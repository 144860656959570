import logo from './logo.svg';
import './App.css';
import Countdown from 'react-countdown';
import { Button } from 'react-bootstrap';
import { useState } from 'react';
import trackIntro from "./audio/intro.m4a";
import trackHappyBirthday1 from "./audio/happy_birthday_1.m4a";
import trackHappyBirthday2 from "./audio/happy_birthday_2.m4a";
import trackHappyBirthday3 from "./audio/happy_birthday_3.m4a";
import imageSalma from "./image/salma.jpeg";

function App() {
  const salmaBirthday = new Date("2023-07-17T00:00:00.000+07:00");

  const [showMessage, setShowMessage] = useState(false);

  const handleMessage = () => {
    const now = new Date();

    if (now.getDate() >= salmaBirthday.getDate() && now.getMonth() >= salmaBirthday.getMonth()) {
      setShowMessage(!showMessage);
    }

    else {
      alert("Yah belum bisa dibuka nih :( tunggu dulu ya!");
    }
  }

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
      // Render a countdown
      return (
        <span>
          {days} days<br />{hours} hours<br />{minutes} minutes<br />{seconds} seconds
        </span>
      );
  };

  return (
    <div className="App">
      <div className="timer">
        <h2>Counting to your special day</h2><br />
        <div className="countdown"><b><Countdown date={salmaBirthday} renderer={renderer} /></b></div>
      </div>
      <div className="message">
        <Button variant="info" onClick={handleMessage}>Open Message</Button>
        {showMessage ? <div className="birthdayMessage">
          Hi my dear,<br /><br />
          Happy birthday to you, happy birthday to you...<br />
          Happy birthday, happy birthday...<br />
          Happy birthday to you!<br /><br />
          Selamat ulang tahun ya dek!<br /><br />
          Can't imagine that how lucky I am to have you, hehehehe.<br /><br />
          Pokoknya best wishes buat semuanya, semoga kamu sehat selalu dan juga harapan-harapan kamu di tahun ini bisa tercapai semua.<br /><br />
          Semoga makin sayang sama aku juga, hihihihihi.<br /><br />
          Akhir kata, I love you so much, dek Salma-ku sayang!<br /><br />
          <img className="imageSalma" src={imageSalma} /><br /><br />
          Sincerely,<br /><br /><br /><br />
          Masmu ternyebelin<br /><br />
          <audio src={trackIntro} type="audio/m4a" controls></audio><br />
          <audio src={trackHappyBirthday1} type="audio/m4a" controls></audio><br />
          <audio src={trackHappyBirthday2} type="audio/m4a" controls></audio><br />
          <audio src={trackHappyBirthday3} type="audio/m4a" controls></audio><br />
        </div> : null}
      </div>
    </div>
  );
}

export default App;
